
import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import api from './api'
import Breadcrumbs from './breadcrumbs'
import Search from './search'
import OccDetails from './occdetails'
import AutoComplete from './autocomplete2'

const NAME = 'action'
const DATA_KEY = 'ff.action'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_TOGGLE = '[data-ff-toggle="action"]'

class Actions {
  static to_dish_ingredients(evTrgt) {
    ff.search.levels = 0
    ff.search.mode = 2
    ff.el.product_menu.hide()
    ff.el.dish_menu.show()
  }

  static add_dish(evTrgt, dish) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    try {
      if (typeof dish === 'string') {
        dish = JSON.parse(dish)
      } 
    } catch (e) {}

    if ((Number.isNaN(dish.dish_id) || dish.dish_id < 1) && dish.label.length < 3) {
      Dialog.alert(evTrgt, { content: lang.diary_js_empty_dish_message })
      return false
    }

    ff.el.add_products.hide()
    ff.el.dish_content.innerHTML = ''
    ff.el.product_content.innerHTML = ''
    Search.resetSearch()
    AutoComplete.resetSearch()
    ff.used_product_categories = []
    ff.currentdish = dish
    OccDetails.set_is_dish(true)
    Breadcrumbs.remove()
    Breadcrumbs.add(dish.label)
    Breadcrumbs.add(lang.diary_js_breadcrumb_adding_ingredients)

    api.post(
      ff.pages.ajax,
      {
        act: 'add_dish',
        occasion_id: ff.occasion_id,
        usage_course_id: OccDetails.get_usage_course_id(),
        dish_id: dish.dish_id,
        other_dish_id: dish.other_dish_id,
        label: dish.label,
        SES
      },
      true
    ).then(data => {
      ff.currentdish.usage_dish_id = data.trim()
      ff.el.product_search.show()
      for (let y = 0; y < [ff.el.dish_search, ff.el.add_dish].length; y++) {
        let el = [ff.el.dish_search, ff.el.add_dish][y]
        el.hide()
      }

      OccDetails.show_selected_items()
      OccDetails.show_favorite_ingredients()
    })
    if (dish.dish_id > 0) {
      OccDetails.show_dishmenu(dish.dish_id, ff)
    } else {
      ff.el.product_menu.show()
      ff.el.dish_menu.hide()
      ff.el.to_ingredients.hide()
    }
  }

  static edit_dish(evTrgt, thisDish) {
    if (typeof thisDish === 'string') {
      thisDish = JSON.parse(thisDish)
    }

    if (ff.currentdish.usage_dish_id > 0) {
      return
    }

    ff.el.add_products.hide()
    ff.currentdish = thisDish
    ff.el.dish_content.innerHTML = ''
    ff.el.product_content.innerHTML = ''
    ff.used_product_categories = []
    ff.el.product_search.show()
    ff.el.dish_search.hide()
    Search.resetSearch()
    OccDetails.set_is_dish(true)
    Breadcrumbs.remove()
    Breadcrumbs.add(thisDish.label)
    Breadcrumbs.add(lang.diary_js_breadcrumb_adding_ingredients)
    OccDetails.show_selected_items()
    OccDetails.show_favorite_ingredients()
    if (thisDish.dish_id > 0) {
      OccDetails.show_dishmenu(thisDish.dish_id, ff)
    } else {
      ff.el.product_menu.show()
      ff.el.dish_menu.hide()
      ff.el.to_ingredients.hide()
    }
  }

  static add_product(evTrgt, product_data) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''
    const products = JSON.parse(product_data);

    ff.el.add_products.hide()

    api.post(
      ff.pages.ajax,
      {
        act: 'add_product',
        product_id: products.product_id,
        usage_course_id: OccDetails.get_usage_course_id(),
        other_product_id: products.other_product_id,
        usage_dish_id: ff.currentdish.usage_dish_id,
        SES
      },
      true
    ).then(data => OccDetails.show_selected_items())
  }

  static add_other_product(evTrgt) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    if (document.getElementById('product_label').value.length < 3) {
      Dialog.alert(evTrgt, { content: lang.diary_js_empty_product_message })
      return false
    }

    api.post(
      ff.pages.ajax,
      {
        act: 'add_other_product',
        product_label: document.getElementById('product_label').value,
        categories: document.getElementById('product_categories').value,
        usage_dish_id: ff.currentdish.usage_dish_id,
        usage_course_id: ff.get_usage_course_id(),
        SES
      },
      true
    ).then(data => OccDetails.show_selected_items())
  }

  static delete_product(evTrgt, usage_product_id) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    Dialog.confirm(
      evTrgt,
      {
        content: lang.diary_js_confirm_delete_product,
        btnOKLabel: lang.diary_ok_btn || 'ok',
        btnCancelLabel: lang.diary_cancel_btn || 'cancel',
        btnOKCallback: (dialog, evt) => {
          let popup = dialog
          api.post(
            ff.pages.ajax,
            {
              act: 'delete_product',
              usage_product_id,
              edit: ff.edit,
              SES
            },
            true
          ).then(
            data => {
              OccDetails.show_selected_items()
              const pop = Dialog.getInstance(popup)
              pop.hide(evt)
            }
          )
          return true
        }
      }
    )
  }

  static delete_dish(evTrgt, usage_dish_id) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    Dialog.confirm(
      evTrgt,
      {
        content: lang.diary_js_confirm_delete_dish,
        btnOKLabel: lang.diary_ok_btn || 'ok',
        btnCancelLabel: lang.diary_cancel_btn || 'cancel',
        btnOKCallback: (popup, evt) => {
          document.getElementById('searchdishes').value = ''
          if (usage_dish_id == ff.currentdish.usage_dish_id) {
            ff.currentdish = {}
            Breadcrumbs.remove()
            Search.resetSearch()
            OccDetails.show_dish_view()
          }

          api.post(
            ff.pages.ajax,
            {
              act: 'delete_dish',
              occasion_id: ff.occasion_id,
              usage_dish_id,
              edit: ff.edit,
              SES
            },
            true
          ).then(data => {
            OccDetails.show_selected_items()
            const pop = Dialog.getInstance(popup)
            pop.hide(evt)
          })
          return true
        }
      }
    )
  }

  static delete_course(evTrgt, usage_course_id) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    Dialog.confirm(
      evTrgt,
      {
        content: lang.diary_js_confirm_delete_course,
        btnOKLabel: lang.diary_ok_btn || 'ok',
        btnCancelLabel: lang.diary_cancel_btn || 'cancel',
        btnOKCallback: (popup, evt) => {
          if (usage_course_id == OccDetails.get_usage_course_id()) {
            ff.currentdish = {}
            ff.previous_course()
            ff.el.dish_content.innerHTML = ''
            ff.el.product_content.innerHTML = lang.diary_js_product_content
          }

          api.post(
            ff.pages.ajax,
            {
              act: 'delete_course',
              occasion_id: ff.occasion_id,
              usage_dish_id: usage_course_id,
              edit: ff.edit,
              SES
            },
            true
          ).then(data => {
            OccDetails.show_selected_items()
            const pop = Dialog.getInstance(popup)
            pop.hide(evt)
          })
          return true
        }
      }
    )
  }

  static complete_dish(evTrgt) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    document.getElementById('searchdishes').value = ''
    ff.used_product_categories = []
    Breadcrumbs.remove()

    api.post(
      ff.pages.ajax,
      {
        act: 'complete_dish',
        occasion_id: ff.occasion_id,
        usage_dish_id: ff.currentdish.usage_dish_id,
        SES
      },
      true
    ).then(data => {
      if (data.includes('true')) {
        OccDetails.show_dish_view()
      } else {
        Dialog.confirm(
          evTrgt,
          {
            content: lang.diary_js_confirm_dish_validation +
                        '<ul><li>' + JSON.parse(data).join('</li><li>') + '</li></ul>',
            btnOKLabel: lang.diary_confirm_btn || 'ok',
            btnCancelLabel: lang.diary_dont_confirm_btn || 'cancel',
            btnOKCallback: (popup, evt) => {
              const pop = Dialog.getInstance(popup)
              pop.hide(evt)
              OccDetails.show_dish_view()
            }
          }
        )
      }
    })
    Search.resetSearch()
  }

  static next(evTrgt, what) {
    let callback = function () {}
    switch (what) {
      case 'dish':
        callback = OccDetails.show_dish_view
        break
      case 'product':
        callback = OccDetails.show_product_view
        break
      default:
    }

    if (ff.currentdish.usage_dish_id) {
      this.constructor.complete_dish(callback)
    } else {
      callback()
    }
  }

  static confirm_cancel(evTrgt, link) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    Dialog.confirm(
      evTrgt,
      {
        content: lang.diary_js_confirm_cancel_occasion,
        btnOKLabel: lang.diary_ok_btn || 'ok',
        btnCancelLabel: lang.diary_cancel_btn || 'cancel',
        btnOKCallback: (popup, evt) => {
          api.post(
            ff.pages.ajax,
            {
              act: 'delete_occasion',
              occasion_id: ff.occasion_id,
              SES
            },
            true
          ).then(data => document.location.href = ff.pages.day)
          return true
        }
      }
    )
    return false
  }
}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, event => {
  let trgt = event.target
  let attribs = Manipulator.getDataAttributes(trgt)

  if (trgt.tagName === 'A' || (trgt.parentNode && trgt.parentNode.tagName === 'A') || trgt.tagName === 'AREA') {
    event.preventDefault()
  }

  if (!trgt || !attribs.hasOwnProperty('action')) {
    trgt = event.target.closest(SELECTOR_DATA_TOGGLE)
    attribs = Manipulator.getDataAttributes(trgt) 
  }

  Actions[attribs.action](trgt, (attribs.hasOwnProperty('data') ? attribs.data : {}))
})

export default Actions
