import {
    getElementFromSelector
  } from './util/index'
  
import Data from './dom/data'
import OccDetails from './occdetails'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import Help from './help'
  
const NAME = 'expandmenu'

class expandMenu {
      
    constructor (parentselector, childselector, titleselector) {
        this.items = document.querySelectorAll(parentselector)
        this.childselector = childselector
        this.current = null
        this.titleselector = titleselector || 'span';
        if (this.items.length) this.items.forEach ( (item) => this.initItem(item) );
    }

    initItem (element) {
        element.childs = element.querySelectorALl(this.childselector);
        element.expand = element.querySelector(this.titleselector);
        element.expand.observe('click', this.itemClick.bind(element));
    }

    itemClick() {
        $(this.childs).invoke('toggle');
        if (this.classList.contains('selected')) {
            this.classList.remove('selected');
        } else {
            this.classList.add('selected');
        }
    }

    childClick(item, outer_key) {
        if (this.current)
            document.querySelector(this.current).setStyle({
                backgroundColor: ''
            });
        this.current = item;
        item.style.backgroundColor = '#f6f6f6'
        OccDetails.browse_hierarchy(item.id, 0);
        return false;
    }

    static create(parentselector, childselector, titleselector){
        return new expandMenu(parentselector, childselector, titleselector)
    }
    
}

export default expandMenu;