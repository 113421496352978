/* jshint esversion: 6 */

if (!('hide' in Element.prototype)) {
  Element.prototype.hide = function () {
    // console.log(this.id + ' hide')
    this.classList.add('hidden')
  }
}

if (!('show' in Element.prototype)) {
  Element.prototype.show = function () {
    // console.log(this.id + ' show')
    this.classList.remove('hidden')
  }
}

if (!('getclosest' in Element.prototype)) {
  Element.prototype.getclosest = function (selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
              const matches = (this.document || this.ownerDocument).querySelectorAll(s)
              let i = matches.length
              while (--i >= 0 && matches.item(i) !== this) {}
              return i > -1
            }
    }

    let currentTarget = this
    // Get the closest matching element
    for (; currentTarget && currentTarget !== document; currentTarget = currentTarget.parentNode) {
      if (currentTarget.matches(selector)) {
        return currentTarget
      }
    }

    return null
  }
}

if (!('strip' in String.prototype)) {
  String.prototype.strip = function () {
    return this.replace(/^\s+/, '').replace(/\s+$/, '')
  }
}

if (!('empty' in String.prototype)) {
  String.prototype.empty = function () {
    return (!this || this.length === 0)
  }
}

if (!('setInnerHTML' in Element.prototype)) {
  Element.prototype.setInnerHTML = function (html) {
    this.innerHTML = html
    for (const oldScript of [...this.querySelectorAll('script')]) {
      const newScript = document.createElement('script')
      for (const attr of [...oldScript.attributes]) {
        newScript.setAttribute(attr.name, attr.value)
      }

      newScript.append(document.createTextNode(oldScript.innerHTML))
      oldScript.parentNode.replaceChild(newScript, oldScript)
    }
  }
}

if (!('fadeOut' in Element.prototype)) {
  Element.prototype.fadeOut = function (milliTime) {
    Object.assign(this.style, {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s ' + Math.floor(milliTime / 1000) + 's, opacity ' + Math.floor(milliTime / 1000) + 's linear'
    })
  }
}

if (!('fadeIn' in Element.prototype)) {
  Element.prototype.fadeIn = function (milliTime) {
    Object.assign(this.style, {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity ' + Math.floor(milliTime / 1000) + 's linear'
    })
  }
}

if (!('getValue' in Element.prototype)) {
  Element.prototype.getValue = function () {
    const retArray = []
    switch (this.nodeName) {
      case 'file':
        break

      case 'TEXTAREA':
        return this.value

      case 'SELECT':
        switch (this.type) {
          case 'select-multiple':
            for (let j = this.options.length - 1; j >= 0; j -= 1) {
              if (this.options[j].selected) {
                retArray.push(this.options[j].value)
              }
            }

            return retArray
          case 'select-one':
          default:
            return this.value
        }

      case 'BUTTON':
        return this.value

      case 'INPUT':
      default:
        switch (this.type) {
          case 'checkbox':
          case 'radio':
            if (this.checked) {
              return this.value
            }

            break
          case 'text':
          case 'hidden':
          case 'password':
          case 'button':
          case 'reset':
          case 'submit':
          default:
            return this.value
        }

        break
    }
  }
}

import 'mdn-polyfills/MouseEvent'
import 'mdn-polyfills/CustomEvent'
import 'mdn-polyfills/Node.prototype.append'
import 'mdn-polyfills/Node.prototype.addEventListener'
import 'mdn-polyfills/Node.prototype.children'
import 'mdn-polyfills/Node.prototype.remove'
import 'mdn-polyfills/Element.prototype.closest'
import 'mdn-polyfills/Element.prototype.matches'
import 'whatwg-fetch'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import {
  ajaxLoad
} from './src/util/index'
import api from './src/api'
import Button from './src/button'
import Collapse from './src/collapse'
import Dialog from './src/dialog'
import Modal from './src/modal'
import Popover from './src/popover'
import Tab from './src/tab'
import Tooltip from './src/tooltip'
import Lightmode from './src/lightmode'
import Home from './src/home'
import Help from './src/help'
import DayOverview from './src/dayoverview'
import OccAttributes from './src/occattributes'
import OccLatamMatc from './src/occlatammatc'
import OccCourses from './src/occcourses'
import OccDetails from './src/occdetails'
import expandMenu from './src/expandmenu'
import AutoComplete from './src/autocomplete2'
import ProductTree from './src/producttree'
import Search from './src/search'
import Actions from './src/actions'
import Needs from './src/needs'
import TabContainer from './src/tabcontainer'

window.addEventListener('load', ajaxLoad())

export default {
  api,
  Button,
  Collapse,
  Dialog,
  Modal,
  Popover,
  Tab,
  Tooltip,
  Lightmode,
  Home,
  Help,
  DayOverview,
  OccAttributes,
  OccLatamMatc,
  OccCourses,
  OccDetails,
  expandMenu,
  AutoComplete,
  ProductTree,
  Search,
  Actions,
  Needs,
  TabContainer
}
