
import {
  getElementFromSelector
} from './util/index'

import Data from './dom/data'
import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import Help from './help'

const NAME = 'occattributes'
const DATA_KEY = 'ff.occattributes'
const DATA_API_KEY = '.data-api'
const EVENT_KEY = `.${DATA_KEY}`

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_SUBMIT = `submit${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_TOGGLE = '[data-ff-target="guest"]'

const Default = {
  ate: 1,
  lunchbox: 2,
  not: 3,
  out: 4
}

class OccAttributes {
  constructor(config) {
    this.config = config
    this.helpTopic = 'occasion_attributes'
    this.day_overview = ff.pages.day
    this.persons = this.config.persons
    this.attendance = this.config.attendance
    this.wfh = this.config.wfh
    this.guests = 0
    this.questions = this.config.questions
    this.values = {
      ate: Default.ate,
      lunchbox: Default.lunchbox,
      not: Default.not,
      out: Default.out
    }
    this.el = {}
    this.el.that = this
    this.el.attendance_inputs = document.querySelectorAll('#attendance input[type=radio]')
    this.el.attendance_time_label = document.querySelectorAll('#attendance .meal_att_column.timelabel')[0]
    this.el.attendance_time = document.querySelectorAll('#attendance select')
    this.el.guests_inputs = document.querySelectorAll('#attendance #guests input')
    this.el.guests_time = document.querySelectorAll('#attendance #guests select')
    this.el.guest_count = document.getElementById('guest_count')
    this.el.guests_text = document.getElementById('guests_text')
    this.el.form = document.getElementById('f1')
    this.el.all_ate = document.getElementById('all_ate')

    EventHandler.on(this.el.form, EVENT_SUBMIT, event => {
      return this.validate_meal_form(event)
    })
    EventHandler.on(this.el.all_ate, EVENT_CHANGE, () => {
      this.check_radio_buttons()
    })
    EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, event => {
      const trgt = event.target

      if (trgt.tagName === 'A' || (trgt.parentNode && trgt.parentNode.tagName === 'A') || trgt.tagName === 'AREA') {
        event.preventDefault()
      }

      const config = {
        ...Manipulator.getDataAttributes(trgt)
      }
      if (config.toggle == 'subtract') {
        this.sub_guest()
      } else {
        this.add_guest()
      }
    })
    for (const ateRadio of this.el.attendance_inputs) {
      EventHandler.on(ateRadio, EVENT_CLICK, event => {
        // use name - assumes 1-n for attendees and 25 for guests
        const radioId = event.target.name
        const r = /\d+/
        const personId = Number.parseInt(radioId.match(r), 10)
        this._check_ate()
        if (event.target.value > this.values.lunchbox) {
          this._uncheck_all_ate()
        }

        this._check_attendance(personId, event.target)
      })
    }

    for (const timeSelect of this.el.attendance_time) {
      EventHandler.on(timeSelect, EVENT_CLICK, event => {
        this._change_time(event.target)
      })
      EventHandler.on(timeSelect, EVENT_CHANGE, event => {
        this._change_time(event.target)
      })
    }

    this.el.attributes_table = document.getElementById('attribute_questions')
    this.el.attributes_inputs = document.querySelectorAll('#attribute_questions input, #attribute_questions select')
    this.update_guests_text()
    this._check_ate()
    Help.init({ topic: this.helpTopic })
  }

  // Getters

  static get Default() {
    return Default
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  update_guests_text() {
    let guesttxt = ''
    switch (Number(this.el.guest_count.value)) {
      case 0:
        for (const inp of this.el.guests_inputs) {
          inp.hide()
        }

        if (this.el.attendance_time.length > 0) {
          for (const timeSelect of this.el.guests_time) {
            timeSelect.hide()
          }
        }

        guesttxt = lang.diary_js_add_guests
        break
      case 1:
        for (const inp of this.el.guests_inputs) {
          inp.show()
        }

        if (this.el.guests_time.length > 0) {
          for (const timeSelect of this.el.guests_time) {
            timeSelect.show()
          }
        }

        guesttxt = this.el.guest_count.value + '&nbsp;' + lang.diary_js_guest
        break
      default:
        for (const inp of this.el.guests_inputs) {
          inp.show()
        }

        if (this.el.guests_time.length > 0) {
          for (const timeSelect of this.el.guests_time) {
            timeSelect.show()
          }
        }

        guesttxt = this.el.guest_count.value + '&nbsp;' + lang.diary_js_guests
        break
    }

    this.el.guests_text.innerHTML = guesttxt
  }

  add_guest() {
    this.el.guest_count.value++
    this.update_guests_text()
    this._check_ate()
  }

  sub_guest() {
    if (this.el.guest_count.value < 1) {
      return false
    }

    this.el.guest_count.value--
    this.update_guests_text()
    this._check_ate()
  }

  _check_ate() {
    const ateVals = this.count_ate_values()

    if (ateVals.ate == 0) {
      if (this.el.attendance_time.length > 0) {
        this.el.attendance_time_label.hide()
      }

      this.el.attributes_table.hide()

      for (const timeSelect of this.el.attendance_time) {
        timeSelect.hide()
      }
    } else {
      if (this.el.attendance_time.length > 0) {
        this.el.attendance_time_label.show()
      }

      this.el.attributes_table.show()
      for (const attendees of this.el.attendance_inputs) {
        const checkbox = document.getElementById(attendees.id)
        if (checkbox && checkbox.checked == true) {
          const ateVal = Number.parseInt(checkbox.value, 10)
          const matches = checkbox.name.match(/\[(.*?)\]/)
          let submatch = false
          if (matches) {
            submatch = matches[1];
          }

          if (ateVal !== this.values.ate && 
            ateVal !== this.values.lunchbox && 
            this.el.attendance_time.length > 0 && submatch) {
            document.getElementById('attendance_time_' + submatch).hide()
          }
        }
      }
    }

    return ateVals.ate
  }

  _check_attendance(person_id, element) {
    if (this.el.attendance_time.length <= 0) {
      return true
    }

    if (this.attendance && this.attendance[person_id] && (this.attendance[person_id].attendance == this.values.ate || this.attendance[person_id].attendance == this.values.lunchbox) && (element.value != this.values.ate && element.value != this.values.lunchbox)) {
      Dialog.confirm({
        content: lang.diary_js_confirm_override_whw,
        buttons: [{
          label: lang.diary_js_ok,
          action() {
            element.checked = true
            if (document.querySelector('attendance_time_' + person_id)) {
              document.querySelector('attendance_time_' + person_id).hide()
            }

            return true
          }
        }]
      })
      return false
    }

    if ((element.value == this.values.ate || element.value == this.values.lunchbox) && this.el.attendance_time.length > 0) {
      document.getElementById('attendance_time_' + person_id).show()
      document.getElementById('attendance_time_' + person_id).focus()
    } else {
      document.getElementById('attendance_time_' + person_id).hide()
    }

    return true
  }

  _change_time(element) {
    if (element.value == '') {
      return
    }

    for (const timeVal of this.el.attendance_time) {
      if (timeVal.value == '') {
        timeVal.value = element.value
      }
    }
  }

  get_ate_box(time_el) {
    return document.querySelector(time_el.parentNode.parentNode + ' input[value=' + this.values.ate + ']')
  }

  check_radio_buttons() {
    if (!this.el.all_ate.checked) {
      return false
    }

    const radios = this.el.attendance_inputs
    for (const atTimeRadio of radios) {
      if (atTimeRadio.value == Default.ate) {
        atTimeRadio.checked = true
      }
    }

    this._check_ate()
    if (this.el.attendance_time.length > 0) {
      this.el.attendance_time_label.show()
      for (const timeSelect of this.el.attendance_time) {
        timeSelect.show()
      }

      if (this.el.guest_count.value == 0) {
        for (const timeSelect of this.el.guests_time) {
          timeSelect.hide()
        }
      }
    }
  }

  _uncheck_all_ate() {
    const guest_index = 'per_' + this.persons + '_att_' + Default.ate
    if (this.el.guest_count > 0 && document.getElementById(guest_index).checked == true) {
      return false
    }

    this.el.all_ate.checked = false
  }

  count_ate_values() {
    let dideat = 0
    let did_eat_out = 0
    let didnt_eat_at_all = 0
    const guestInputs = [...this.el.guests_inputs]

    for (const inp of this.el.attendance_inputs) {
      const checkbox = document.getElementById(inp.id)
      if (Boolean(guestInputs.find((node, id) => node.id == inp.id)) && Number.parseInt(this.el.guest_count.value, 10) <= 0) {} else if (checkbox && checkbox.checked == true) {
        switch (Number.parseInt(checkbox.value, 10)){
          case this.values.ate:
          case this.values.lunchbox:
            dideat++
            break
          case this.values.out:
            did_eat_out++
            break
          case this.values.not:
            didnt_eat_at_all++
            break
        }
      }
    }

    return {
      ate: dideat,
      out: did_eat_out,
      not: didnt_eat_at_all
    }
  }

  validate_meal_form(event) {
    let errMsg = ''
    const ate = this.count_ate_values()
    const persons = this.persons + (this.el.guest_count.value > 0 ? 1 : 0)
    const unanswered = (typeof this.questions === 'Object') ? this.questions : JSON.parse(this.questions)

    if (Number.parseInt(ate.out + ate.not, 10) === Number.parseInt(persons, 10)) {
      this.el.form.action = this.config.day_overview
    } else if (ate.ate + ate.out + ate.not != persons) {
      errMsg += lang.diary_js_message_complete_entries + ' "' + this.config.attendance_description + '"<br />'
    }

    if (ate.ate > 0) {
      if (this.el.attendance_time.length > 0) {
        for (let y = 0; y < this.el.attendance_time.length - (this.el.guest_count.value > 0 ? 0 : 1); y++) {
          const checkbox = document.querySelector('#attendance input:checked[type=radio][id^=\'per_' + y + '\']')
          if (this.el.attendance_time[y].value == '' &&
						(checkbox && (Number(checkbox.value) == this.values.ate || Number(checkbox.value) == this.values.lunchbox))) {
            errMsg += lang.diary_js_message_attendance_time_missing + '<br />'
            break
          }
        }
      }

      for (const thisel of this.el.attributes_inputs) {
        switch (thisel.type) {
          case 'hidden':
            if (thisel.hasAttribute('data-ff-external_id')) {
              delete unanswered[Manipulator.getDataAttribute(thisel, ('external_id'))]
            }

            break;
          case 'select':
          case 'select-one':
            if (thisel.selectedIndex > 0) {
              delete unanswered[thisel.id]
            }

            break;
          case 'radio':
          case 'checkbox':
          default:
            if (thisel.checked == true) {
              if (thisel.hasAttribute('data-ff-external_id')) {
                delete unanswered[Manipulator.getDataAttribute(thisel, ('external_id'))]
              } else if (thisel.hasAttribute('questionData')) {
                try {
                  let params = JSON.parse(thisel.hasAttribute('questionData'))
                  delete unanswered[params.external_id]
                } catch (error) {}
              } else {
                delete unanswered[thisel.id]
              }

              break;
            }
        }
      }

      if (Object.keys(unanswered).length) {
        for (const i in unanswered) {
          if (unanswered[i] && unanswered[i].description && Object.keys(unanswered[i]).length && unanswered[i].description !== undefined) {
            errMsg += lang.diary_js_message_complete_entries + ' "' + unanswered[i].description + '"<br />'
          }
        }
      }
    }

    if (errMsg !== '') {
      event.preventDefault()
      Dialog.alert(this.el.form, { content: errMsg })
      return false
    }

    // this.el.form.submit();
    return true
  }
}

export default OccAttributes
