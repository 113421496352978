
import {
  getElementFromSelector
} from './util/index'

import Data from './dom/data'
import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'

const NAME = 'occlatammatc'
const DATA_KEY = 'ff.occlatammatc'
const DATA_API_KEY = '.data-api'
const EVENT_KEY = `.${DATA_KEY}`

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_SUBMIT = `submit${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

class OccLatamMatc {
  constructor(config) {
    this.config = config
    this.questions = this.config.questions
    this.wfh = this.config.wfh

    for (let question in this.questions) {
      let thisQuestion = this.questions[question]
      if (thisQuestion.type === 'MATC') {
        let matcInputs = document.querySelectorAll('input[type="checkbox"][id^="internal_id__external_id_' + thisQuestion.external_id + '"]')
        for (const input of matcInputs) {
          EventHandler.on(input, EVENT_CHANGE, ev => {
            this.checkCheckButtons(ev)
          })
        }
      }
    }
  }

  // Getters

  static get Default() {
    return Default
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  checkCheckButtons(evnt) {
    const matcInputsChecked = document.querySelectorAll(
      'input[type="checkbox"][id^="internal_id__external_id_' + evnt.target.dataset.ffExternal_id + '"]:checked')
    const matcInputs = document.querySelectorAll(
      'input[type="checkbox"][id^="internal_id__external_id_' + evnt.target.dataset.ffExternal_id + '"]')

    if (evnt.target.id == matcInputs[matcInputs.length - 1].id && evnt.target.checked) {
      for (const check of matcInputsChecked) {
        if (check.id != matcInputs[matcInputs.length - 1].id) {
          check.checked = false
        }
      }
      return false
    }

    if (evnt.target.id != matcInputs[matcInputs.length - 1].id && matcInputs[matcInputs.length - 1].checked) {
      matcInputs[matcInputs.length - 1].checked = false
    }

    if ((matcInputsChecked.length > 2 && evnt.target.dataset.ffExternal_id !== this.wfh) || matcInputs[matcInputs.length - 1].checked) {
      evnt.target.checked = false
    }
  }
}

export default OccLatamMatc
