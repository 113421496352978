
import {
  getElementFromSelector
} from './util/index'

import EventHandler from './dom/event-handler'
import Dialog from './dialog'
import Help from './help'
import Breadcrumbs from './breadcrumbs'
import Search from './search'
import ProductTree from './producttree'
import TabContainer from './tabcontainer'
import api from './api'

const NAME = 'occcourses'
const DATA_KEY = 'ff.occcourses'
const DATA_API_KEY = '.data-api'
const EVENT_KEY = `.${DATA_KEY}`
const MIN_SEARCH = 2

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_SUBMIT = `submit${EVENT_KEY}`
const EVENT_KEYUP = `keyup${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

class OccDetails {
  constructor(config) {
    this.config = config
    this.helpTopic = 'occasion_details'

    ff.used_product_categories = []
    ff.answer_count = 0
    ff.is_dish = false
    ff.currentdish = {}
    ff.occasion_id = this.config.occasion_id || 0
    ff.category_id = this.config.category_id || 0
    ff.edit = this.config.edit || false
    ff.whwquestionid = this.config.whwquestionid || 0
    ff.search = {
      levels: 0,
      mode: 1
    },
    ff.short_process_id = this.config.short_process_id || 0
    ff.trees = this.config.trees || []
    ff.treeRoots = this.config.treeRoots || []
    ff.courses = this.config.courses || []
    ff.current_course = 0
    ff.allow_next_course = false
    ff.delayToolTip = this.config.delayToolTip || 500
    ff.breadcrumbs = {}
    // ff.display = DetailsDisplay.init()
    ff.occasion_label = this.config.occasion_label || ''
    ff.el = {
      product_menu: document.getElementById('contentleftmenu'),
      product_content: document.getElementById('product_content'),
      to_ingredients: document.getElementById('to_dish_ingredients'),
      dish_content: document.getElementById('dish_content'),
      dish_menu: document.getElementById('contentleftdishrecipe'),
      dish_list: document.getElementById('dish_list'),
      dish_search: document.getElementById('dish_search'),
      product_search: document.getElementById('product_search'),
      search: document.getElementById('search'),
      search_form: document.getElementById('searchform'),
      add_products: document.getElementById('add_products'),
      add_dish: document.getElementById('add_dish'),
      favdishmenu: document.getElementById('favdishmenu'),
      favmenu: document.getElementById('favmenu')
    }

    ff.dishTabs = new TabContainer({
      buttons: ff.el.dish_menu,
      content: ff.el.dish_content,
      css: {
        active: 'selecteddish',
        content: 'tab'
      }
    })
    Breadcrumbs.create(ff.occasion_label)
    if (ff.trees) {
      this.constructor.generateProductTrees(ff.trees)
    }

    if (ff.short_process_id > 0) {
      this.constructor.set_short_process(ff.short_process_id)
    } else if (ff.courses.length > 0) {
      this.constructor.next_course(ff.courses[0].usage_course_id, true)
    } else {
      Dialog.alert(document, { content: lang.diary_js_invalid_occasion })
    }

    ff.el.dish_content.show()
    if (ff.el.dish_list.length) {
      ff.el.dish_list.fadeOut(150)
    }

    Help.init({ topic: this.helpTopic })
  }

  // Getters
  static get DATA_KEY() {
    return DATA_KEY
  }

  static toggle_view() {
    const dishelements = [ff.el.dish_content, ff.el.dish_search, ff.el.add_products]
    const productelements = [ff.el.product_content, ff.el.product_search, ff.el.add_dish]

    if (ff.is_dish) {
      if (!ff.currentdish.usage_dish_id) {
        this.show_used_dishes()
      }

      for (const el of dishelements) {
        if (el.querySelector('input[type="text"]')) {
          el.querySelector('input[type="text"]').value = ''
        }

        el.show()
      }

      for (const el of productelements) {
        if (el.querySelector('input[type="text"]')) {
          el.querySelector('input[type="text"]').value = ''
        }

        el.hide()
      }

      ff.el.add_dish.hide()
      ff.el.favmenu.hide()
      ff.el.favdishmenu.hide()
    } else {
      if (ff.short_process_id <= 0) {
        ff.el.to_ingredients.hide()
        ff.el.favmenu.hide()
        ff.el.favdishmenu.hide()
      } else if (!this.get_usage_course_id()) {
        ff.el.favmenu.show()
        ff.el.favdishmenu.hide()
      }

      for (const el of dishelements) {
        if (el.querySelector('input[type="text"]')) {
          el.querySelector('input[type="text"]').value = ''
        }

        el.hide()
      }

      ff.el.dish_list.hide()
      for (const el of productelements) {
        if (el.querySelector('input[type="text"]')) {
          el.querySelector('input[type="text"]').value = ''
        }

        el.show()
      }

      ff.el.add_dish.show()
    }
  }

  static set_is_dish(is_dish) {
    ff.is_dish = is_dish
    if (ff.is_dish) {
      ff.el.to_ingredients.show()
      ff.el.favmenu.hide()
      ff.el.favdishmenu.show()
    } else {
      ff.currentdish = {}
      ff.el.favdishmenu.hide()
    }
  }

  static set_short_process(id, course) {
    ff.short_process_id = id
    this.show_dishmenu(ff.short_process_id, this)
    Breadcrumbs.add(lang.diary_js_breadcrumb_adding_products)
    this.set_is_dish(false)
    if (!this.get_usage_course_id()) {
      ff.el.favmenu.show()
    }

    this.toggle_view()
  }

  static generateProductTrees(trees) {
    const newDiv = document.createElement('div')
    for (const id in trees) {
      if ({}.hasOwnProperty.call(trees, id)) {
        const currentTree = trees[id]
        const treeObj = new ProductTree('ajaxTreeView_' + currentTree.id, currentTree.id, 'category', {
          data: '<span class="title01_left">' + currentTree.label + '</span>'
        })
        for (const item in currentTree.items) {
          if ({}.hasOwnProperty.call(currentTree.items, item)) {
            treeObj.createSublevel(
              currentTree.items[item].id, {
                data: currentTree.items[item].label,
                label_long: currentTree.items[item].label_long
              }
            )
          }
        }

        ff.treeRoots.push(treeObj)
        newDiv.classList.add('maindivider')
        document.getElementById('ajaxTreeView_' + currentTree.id).append(newDiv)
      }  
    }
  }

  static next_course(usage_course_id, init) {
    const isObject = typeof event  ==='object' // is the given argument an object
    const isEvent = isObject ? event.hasOwnProperty('target') : false

    if (!ff.allow_next_course && !init) {
      Dialog.alert(((isEvent) ? event.target : null), { content: lang.diary_js_next_course_not_allowed })
      return false
    }

    //if (isEvent && event.target.nodeType != 9) {
      //let trgt = event.target
      //if (trgt) {
        let modal = document.querySelector('.modal')
        if (modal) {
          const pop = Dialog.getInstance(modal)
          pop.hide(event)
        }
      //}
    //}

    if (usage_course_id > 0) {
      for (const [i, course] of ff.courses.entries()) {
        if (usage_course_id == course.usage_course_id) {
          ff.current_course = i
        }
      }
    } else if (ff.current_course + 1 < ff.courses.length) {
      ff.current_course++
    }

    this.show_selected_items()
    Breadcrumbs.reset()
    Breadcrumbs.add(ff.courses[ff.current_course].label)
    if (ff.courses[ff.current_course].recipe_id > 0) {
      this.set_short_process(ff.courses[ff.current_course].recipe_id)
    } else {
      ff.short_process_id = 0
      this.set_is_dish(true)
      Breadcrumbs.add(lang.diary_js_breadcrumb_adding_dish)
      this.toggle_view()
      for (const el of [
        ff.el.product_menu,
        ff.el.product_search,
        ff.el.dish_menu,
        ff.el.favmenu,
        ff.el.favdishmenu
      ]) {
        el.hide()
      }
    }

    ff.el.dish_content.innerHtml = ''
    ff.el.product_content.innerHtml = lang.diary_js_product_content
    Search.resetSearch()
    document.getElementById('searchdishes').setAttribute('placeholder', lang.diary_js_please_enter_dish)
    return ff.current_course
  }

  static get_usage_course_id() {
    if (!ff.courses || ff.courses.length <= 0) {
      return false
    }

    return ff.courses[ff.current_course].usage_course_id
  }

  static show_recipe_items(label, checkdiv) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    if (document.getElementById(checkdiv)) {
      document.getElementById('check' + checkdiv.id).checked = true
      checkdiv.classList.add('selecteddish')
      if (ff.used_product_categories.includes(checkdiv.id)) {
        return
      }

      ff.used_product_categories.push(checkdiv.id)
    }

    ff.el.dish_content.innerHTML = ''
    ff.el.dish_content.show()

    api.post(
      ff.pages.ajax,
      {
        act: 'show_products_in_cupboard',
        dish_id: ff.currentdish.dish_id || ff.short_process_id,
        label,
        type: checkdiv ? checkdiv.id : 0,
        SES
      },
      true
    ).then(data => {
      this.el.dish_content.setInnerHTML(data)
    })
    ff.el.product_search.show()
  }

  static browse_hierarchy(levels, label, preview) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    ff.search.levels = levels
    ff.search.mode = 1
    ff.search.label = label
    ff.el.product_content.show()
    ff.el.dish_content.hide()

    api.post(
      ff.pages.ajax,
      {
        act: 'browse_hierarchy',
        levels,
        label,
        preview: preview || 0,
        SES
      },
      true
    ).then(data => {
      ff.el.product_content.setInnerHTML(data)
    })
  }

  static show_dishmenu(dishId, localff, usage_dish_id) {
    const fnFF = ff || localff
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''
    let recipeId = 0

    if (dishId > 0) {
      recipeId = dishId
    } else if (fnFF.courses && fnFF.courses.length > 0) {
      recipeId = fnFF.courses[fnFF.current_course].recipe_id
    } else {
      recipeId = fnFF.short_process_id
    }

    api.post(
      ff.pages.ajax,
      {
        act: 'show_dish_recipe',
        occasion_id: fnFF.occasion_id,
        recipe_id: recipeId,
        is_dish: dishId > 0 ? 1 : 0,
        SES
      },
      true
    ).then(data => {
      fnFF.el.dish_menu.setInnerHTML(data)
    })
    fnFF.el.dish_menu.show()
    fnFF.el.product_menu.hide()
    fnFF.el.dish_content.innerHTML = ''
  }

  static show_selected_items() {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    document.getElementById('diarymealcomponentstitle').show()

    api.post(
      ff.pages.ajax,
      {
        act: 'show_selected_items',
        occasion_id: ff.occasion_id,
        usage_dish_id: ff.currentdish.usage_dish_id,
        usage_course_id: this.get_usage_course_id(),
        edit: ff.edit,
        SES
      },
      true
    ).then(data => {
      document.getElementById('diarymealcomponents').setInnerHTML(data)
    })
  }

  static show_my_favorites() {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    TabContainer.deactivate()
    ff.el.product_content.show()

    api.post(
      ff.pages.ajax,
      {
        act: 'show_my_favorites',
        category_id: ff.category_id,
        SES
      },
      true
    ).then(data => {
      ff.el.product_content.setInnerHTML(data)
    })
    Search.resetSearch()
    OccDetails.show_ingredients()
  }

  static show_ingredients() {
    ff.el.product_content.innerHTML = ''
    ff.el.dish_content.innerHTML = ''
    ff.el.product_menu.hide()
    ff.el.product_search.show()
    ff.el.dish_menu.show()
    //Windows.closeAllModalWindows()
    TabContainer.deactivate();
  }

  static show_favorite_ingredients() {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    if (!ff.currentdish) {
      return
    }

    TabContainer.deactivate();
    ff.el.product_content.show()

    api.post(
      ff.pages.ajax,
      {
        act: 'show_favorite_ingredients',
        dish_id: ff.currentdish.dish_id,
        label: ff.currentdish.label,
        SES
      },
      true
    ).then(data => {
      ff.el.product_content.setInnerHTML(data)
    })
    Search.resetSearch()
  }

  static show_used_dishes(pagenr) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    api.post(
      ff.pages.ajax,
      {
        act: 'show_used_dishes',
        pagenr,
        SES
      },
      true
    ).then(data => {
      ff.el.dish_content.setInnerHTML(data)
    })
  }

  static show_dish_view() {
    ff.currentdish = {}
    this.set_is_dish(true)
    Breadcrumbs.remove(1)
    Breadcrumbs.add(lang.diary_js_breadcrumb_adding_dish)
    //Windows.closeAllModalWindows()
    this.toggle_view()
    const hideitems = document.querySelectorAll('ajaxTreeView ._root')
    for (const el of hideitems) {
      el.show()
    }

    ff.el.dish_search.show()
    for (const el of [ff.el.product_menu, ff.el.product_search, ff.el.dish_menu]) {
      el.hide()
    }

    ff.el.product_content.innerHTML = ''
    Search.resetSearch()
    this.show_used_dishes()
    this.show_selected_items()
    document.getElementById('searchdishes').value = ''
    document.getElementById('searchdishes').focus()
  }

  static show_product_view(hideitems) {
    Breadcrumbs.remove()
    Breadcrumbs.add(lang.diary_js_breadcrumb_adding_products)
    this.set_is_dish(false)
    // Windows.closeAllModalWindows();
    this.toggle_view()
    for (const el of [ff.el.dish_search, ff.el.dish_menu]) {
      el.hide()
    }

    if (ff.short_process_id > 0) {
      ff.el.product_menu.hide()
      this.show_dishmenu(ff.short_process_id, ff)
      if (!this.get_usage_course_id()) {
        ff.el.favmenu.show()
      }
    } else {
      for (const el of [ff.el.product_menu, ff.el.product_search]) {
        el.show()
      }

      ff.el.favmenu.hide()
    }

    for (const el of [ff.el.dish_content, ff.el.product_content]) {
      el.innerHTML = ''
    }

    TabContainer.deactivate();
    this.show_selected_items()
    if (!hideitems) {
      for (const el of document.querySelectorAll('#ajaxTreeView ._root')) {
        el.show()
      }
    }
  }
}

EventHandler.on(document.getElementById('search'), EVENT_KEYUP, (ev) => {
  if (ev.target.value.length > MIN_SEARCH) {
    Search.do_search(ev.target.value)
  }
})

export default OccDetails
