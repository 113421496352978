
const NAME = 'breadcrumbs';

const Default = {
    templatSep: ' - ',
    templateItem: "<span></span>"
}

class Breadcrumbs {

    constructor (root){
        ff.el.breadcrumbs = document.getElementById('breadcrumbs');
        ff.breadcrumbs = {
            root: root,
            path: []
        }            
        this.constructor.update();
    }

    static config = {
        ...this.constructor.Default,
    };

    static get Default() {
        return Default
    }	
      
    static create(options) {
        return new Breadcrumbs(options);
    };	    

    static reset() {
        ff.breadcrumbs.path = [];
    }

    static add(message) {
        ff.breadcrumbs.path.push(message);
        this.update();
        return message;
    }

    static remove (steps) {
        if (Number(steps) > 0) {
            for (let i = 0; i < steps && ff.breadcrumbs.path.length > 0; i++) {
                ff.breadcrumbs.path.pop();
            }
        } else {
            ff.breadcrumbs.path.pop();
        }
        this.update();
    }
    
    static update() {
        const element = document.createElement('div')
        let html = "",
            frag = {};

        element.innerHTML = this.Default.templateItem
        frag = element.children[0]
            
        ff.breadcrumbs.path.forEach( step => {    
            frag.innerText = step
            html += (html.length > 0 ? this.Default.templatSep : '') + frag.outerHTML 
        })
        if (ff.el.breadcrumbs) ff.el.breadcrumbs.innerHTML = html;
    }
    
}

export default Breadcrumbs    