
import { defineJQueryPlugin } from './util/index'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import BaseComponent from './base-component'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'home'
const DATA_KEY = 'ff.home'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const CLASS_NAME_ACTIVE = 'active'

const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Home extends BaseComponent {
  // Getters

  static get DATA_KEY() {
    return DATA_KEY
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

EventHandler.on(window, EVENT_LOAD_DATA_API, () => {
  if (document.getElementById('page-index') && document.querySelector('.days-completed') && !document.querySelector('#page-index .content-area .col .custom a')) {
    const tgtDiv = document.querySelector('.content-area .col .custom')

    tgtDiv.parentNode.children[0].remove()
    tgtDiv.setInnerHTML(document.querySelector('.days-completed').innerHTML)
    document.querySelector('.days-completed').setInnerHTML(' ')
  }
})

export default Home
