
import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'

const NAME = 'needs'
const DATA_KEY = 'ff.needs'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_TOGGLE = '[data-ff-toggle="needs"]'

class Needs {
  constructor() {
    ff.currentNeedsQ = 1
    this.close = true
    this.questionContainer = null
    this.currentItem = null
    this.currentTab = null
    this.answerContainer = null
    this.itemContainer = null
    this._dialog = {}
  }

  static openTab(tab) {
    if (this.currentTab) {
      if (!this.currentItem) {
        this.currentItem = this.questionContainer.querySelector('div.items .selected')
      }

      this.currentItem.classList.remove('selected')
      this.currentTab.hide()
    }

    if (!tab.id) {
      tab = this.questionContainer.querySelector('div.answers #' + tab)
    }

    tab.show()
    this.currentItem = this.questionContainer.querySelector('div.items #' + tab.id)
    if (this.currentItem != null) {
      this.currentItem.classList.add('selected')
    }

    this.currentTab = tab
    setTimeout(() => {
      this.currentTab.querySelector('input').focus()
    }, 150)
  }

  static previousTab() {
    if (this.currentTab && this.currentTab.previousElementSibling) {
      this.openTab(this.currentTab.previousElementSibling.id)
    }
  }

  static nextTab() {
    if (this.currentTab && this.currentTab.nextElementSibling) {
      this.openTab(this.currentTab.nextElementSibling.id)
    }
    else if (this.currentTab.querySelectorAll('input[type=radio]:checked, input[type=checkbox]:checked').length === 0) {
      const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden)')
      const form = currentDisplay.closest('form')
      Dialog.alert(form, { content: lang.diary_js_invalid_need_question })
      return false
    }
  }

  static resetTabs() {
    let uncompleted = {}
    this.questionContainer = document.getElementById('questionpopup_' + ff.currentNeedsQ)
    this.answerContainer = this.questionContainer.querySelectorAll('.answerpanel > .answers .answers')
    for (const el of this.answerContainer) {
      el.hide()
      if (el.querySelectorAll('input[type=radio]:checked, input[type=checkbox]:checked').length === 0 && !uncompleted.nodeType) {
        uncompleted = el
      }
    }

    this.itemContainer = this.questionContainer.querySelector('div.items')
    const completed = this.questionContainer.querySelectorAll('div.items .completed')
    //const uncompleted = this.questionContainer.querySelector('div.items li[id*=\'dish\']:not(.completed) , div.items li[id*=\'product\']:not(.completed)')
    if (uncompleted && uncompleted.hasOwnProperty('id')) {
      this.openTab(uncompleted.id)
    }
    else if (completed.length > 0) {
      const lastCompleted = completed[completed.length - 1]
      this.openTab(lastCompleted.id)
      this.nextTab()
    }
    else {
      this.openTab(this.questionContainer.querySelector('div.answers div'))
    }
  }

  static checkKey(keypressed) {
    if (!keypressed) {
      keypressed = document.all.event
    }

    switch (keypressed.keyCode) {
      case Event.KEY_UP:
        this.previousTab()
        break
      case Event.KEY_DOWN:
        this.nextTab()
        break
      default:
        return true
    }

    return false
  }

  static check(checkbox) {
    let completed = false
    const fields = checkbox.closest('.answers').querySelectorAll('input')
    for (let j = 0; j < fields.length && !completed; j++) {
      if (fields[j].checked) {
        completed = true
      }
    }

    if (completed) {
      this.currentItem.classList.add('completed')
    } else {
      this.currentItem.classList.remove('completed')
    }
  }

  static ask_need_questions(evTrgt) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    ff.currentNeedsQ = 1

    this._dialog = Dialog.confirm(evTrgt, {
      extraClasses: 'modal-xl',
      content: {
        url: ff.pages.ajax,
        options: {
          parameters: {
            act: 'ask_need_questions',
            occasion_id: ff.occasion_id,
            category_id: ff.category_id,
            popupnr: this.current,
            edit: ff.edit,
            SES
          }
        }
      },
      events: {
        shown() {
          if (document.querySelector('.popup-1 > .summary')) {
            const oldDialog = document.querySelector('.popup-1 > .summary').closest('.modal')
            if (oldDialog) {
              const modal = Dialog.getInstance(oldDialog)
              modal.dispose()
            }
          }

          const checkboxes = document.querySelectorAll('div#questionpopup_' + ff.currentNeedsQ + ' input')
          for (const el of checkboxes) {
            if (!el.classList.contains('check_all')) {
              EventHandler.on(el, EVENT_CHANGE, ev => {
                if (!ev.target.checked) {
                  document.querySelector('.check_all').checked = false
                }
              })
            }
          }

          EventHandler.on(document.querySelector('.check_all'), EVENT_CHANGE, ev => {
            Needs.check_all(ev.target)
          })
          if (document.getElementById('questiontype' + ff.currentNeedsQ) && document.getElementById('questiontype' + ff.currentNeedsQ).getValue() == 'TABC') {
            Needs.resetTabs()
          }

          for (const el of document.querySelectorAll('[id^="questionpopup_"]')) {
            if (el.id !== 'questionpopup_' + ff.currentNeedsQ) {
              el.hide()
            }
          }

          document.getElementById('questionpopup_' + ff.currentNeedsQ).show()
        }
      },
      buttons: [
        {
          label: lang.diary_need_previous_btn || 'previous',
          action: ev => {
            this.prev(ev)
          }
        },
        {
          label: lang.diary_need_next_btn || 'next',
          action: ev => {
            this.next(ev)
          }
        }
      ]
    })
  }

  static next(ev) {
    const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden)')
    ff.currentNeedsQ = currentDisplay.id.split('_')[1];
    const form = currentDisplay.closest('form')
    for (const el of ev.querySelectorAll('.btn')) {
      el.blur()
    }

    const validation = Number.parseInt(document.getElementById('validation_' + ff.currentNeedsQ).getValue(), 10)
    if (validation === 4) {
      const div = currentDisplay.querySelector('div.answers div.answers:not(.hidden)')
      const divs = currentDisplay.querySelectorAll('div.answers div.answers')
      let notUsed = true
      const fields = div.querySelectorAll('input')
      for (let j = 0; j < fields.length && notUsed; j++) {
        if (fields[j].checked) {
          notUsed = false
        }
      }

      if (notUsed && (div === divs[0] || div === divs[divs.length-1])) {
        Dialog.alert(form, { content: lang.diary_js_invalid_need_question })
        return false
      }

      if (div !== divs[divs.length - 1]) {
        this.resetTabs()
        return false
      }
    }

    if (this.validate()) {
      ff.currentNeedsQ++
      const nextDisplay = document.getElementById('questionpopup_' + ff.currentNeedsQ)
      if (nextDisplay) {
        currentDisplay.hide()
        nextDisplay.show()
        // next_display.scrollIntoView();
        if (document.getElementById('questiontype' + ff.currentNeedsQ) && document.getElementById('questiontype' + ff.currentNeedsQ).getValue() == 'TABC') {
          this.resetTabs()
        }
        // this.resetHeight(this._dialog);
      } else {
        form.submit()
      }
    } else if (document.getElementById('questionid' + ff.currentNeedsQ).value == ff.whwquestionid) {
      Dialog.alert(form, { content: lang.diary_js_invalid_who_had_what })
    } else {
      Dialog.alert(form, { content: lang.diary_js_invalid_need_question })
    }
  }

  static prev(ev) {
    const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden)')
    ff.currentNeedsQ = currentDisplay.id.split('_')[1];
    for (const el of ev.querySelectorAll('.btn')) {
      el.blur()
    }

    if (ff.currentNeedsQ <= 1) {
      const thisModal = document.getElementById('questionpopup_' + ff.currentNeedsQ).closest('.modal')
      const modal = Dialog.getInstance(thisModal)
      modal.dispose()
      return false
    }

    // document.getElementById("questionpopup_" +ff.currentNeedsQ).hide();
    ff.currentNeedsQ--
    const nextDisplay = document.getElementById('questionpopup_' + ff.currentNeedsQ)
    currentDisplay.hide()
    nextDisplay.show()
    if (document.getElementById('questiontype' + ff.currentNeedsQ) && document.getElementById('questiontype' + ff.currentNeedsQ).getValue() == 'TABC') {
      this.resetTabs()
    }

    return false
  }

  static show_occasion_summary(e) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''
    e = e || window.event
    const isObject = typeof e === 'object' // is the given argument an object
    const isEvent = isObject ? e.hasOwnProperty('target') : false

    this.constructor._dialog = Dialog.confirm(
      (isEvent) ? e.target : null,
      {
        extraClasses: 'modal-lg',
        content: {
          url: ff.pages.ajax,
          options: {
            parameters: {
              act: 'show_occasion_summary',
              occasion_id: ff.occasion_id,
              dish_id: ff.currentdish.dish_id || ff.short_process_id,
              usage_dish_id: ff.currentdish.usage_dish_id,
              completed: (!ff.courses || ff.current_course + 1 == ff.courses.length ? 1 : 0),
              edit: ff.edit,
              SES
            }
          }
        },
        buttons: [
          {
            label: lang.diary_close_summary_btn || 'add more',
            data: { 'ff-dismiss': 'dialog' }
          },
          {
            label: lang.diary_meal_complete_btn || 'Complete',
            action: ev => {
              this.ask_need_questions(ev)
            }
          }
        ]
      }
    )
  }

  static check_all(checkAllBox) {
    const checkboxes = this.get_inputs()
    for (const el of checkboxes) {
      el.checked = checkAllBox.checked
    }
  }

  static get_inputs() {
    return document.querySelectorAll('div#questionpopup_' + ff.currentNeedsQ + ' input')
  }

  static validate() {
    const validation = Number.parseInt(document.getElementById('validation_' + ff.currentNeedsQ).getValue(), 10)
    if (validation === 0) {
      return true
    }

    if (validation === 4) {
      const tabsOK = this.validateTabs()
      if (!tabsOK) {
        this.resetTabs()
      }

      return tabsOK
    }

    const rows = document.querySelectorAll('div#questionpopup_' + ff.currentNeedsQ + ' div.row:not(.empty)')
    const columnsUsed = []
    for (let i = 1; i < rows[0].querySelectorAll('.needshead').length; i++) {
      columnsUsed[i] = false
    }

    let allItemsUsed = true
    for (let nr = 1; nr < rows.length; nr++) {
      if (rows[nr].classList.contains('headrow')) {
        continue
      }

      if (rows[nr].querySelectorAll('input[type=checkbox]').length == 0) {
        continue
      }

      const columns = rows[nr].querySelectorAll('div')
      let currentItemUsed = false
      for (let i = 1; i < columns.length; i++) {
        const checkbox = columns[i].querySelector('input[type=checkbox]')
        if (checkbox && checkbox.checked == true) {
          columnsUsed[i] = true
          currentItemUsed = true
        }
      }

      if (!currentItemUsed) {
        allItemsUsed = false
      }
    }

    if (validation & 1 && !allItemsUsed) {
      return false
    }

    if (validation & 2) {
      for (let i = 1; i < columnsUsed.length; i++) {
        if (!columnsUsed[i]) {
          return false
        }
      }
    }

    return true
  }

  static validateTabs() {
    const divs = this.questionContainer.querySelectorAll('div.answers div.answers')
    for (let i = 0; i < divs.length; i++) {
      const fields = divs[i].querySelectorAll('input')
      let used = false
      for (let j = 0; j < fields.length && !used; j++) {
        if (fields[j].checked) {
          used = true
        }
      }

      if (!used) {
        return false
      }
    }

    return true
  }
}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, event => {
  let trgt = event.target
  let attribs = Manipulator.getDataAttributes(trgt)

  if (trgt.tagName === 'A' || (trgt.parentNode && trgt.parentNode.tagName === 'A') || trgt.tagName === 'AREA') {
    event.preventDefault()
  }

  if (!trgt || !attribs.hasOwnProperty('action')) {
    trgt = event.target.closest(SELECTOR_DATA_TOGGLE)
    attribs = Manipulator.getDataAttributes(trgt)
  }

  Needs[attribs.action](trgt, (attribs.hasOwnProperty('data') ? attribs.data : {}))
})

export default Needs
